<template>
  <div id="app">
    <section class="header hero is-bold is-warning has-text-centered"><span class="subtitle">Visitors</span>
    </section>
   
    <section class="login" v-if="!isloggedIn">
      <div class="container" style="margin-top:2rem; margin-bottom:1rem;">

        <b-field label="Email" type="is-danger" message="">
            <b-input v-model="email" type="email" maxlength="50" required="true" value="your@email.net"></b-input>
        </b-field>

        <b-field label="Token" type="is-warning" message="">
            <b-input v-model="inputToken" type="password" maxlength="50" required="true" value=""></b-input>
        </b-field>

        <b-button type="is-success" size="is-large" @click="login">Login</b-button>

      </div>
    </section>

    <section class="query" v-if="isloggedIn">
      <div class="container" style="margin-top:2rem; margin-bottom:1rem;">
        <b-field grouped>

          <b-field>
            <b-button type="is-danger" @click="showOptions=!showOptions" size="is-large" v-if="showOptions">
                <b-icon icon="caret-up"></b-icon>
            </b-button>
            <b-button type="is-success" @click="showOptions=!showOptions" size="is-large" v-if="!showOptions">
                <b-icon  icon="caret-down"></b-icon>
            </b-button>
          </b-field>

          <b-field>
            <b-button type="is-success" @click="executeAction" size="is-large">
                <b-icon icon="sync-alt"></b-icon>
            </b-button>
          </b-field>

          <b-dropdown v-model="action" aria-role="list" style="margin-right:0.75rem;" v-if="!showOptions">
            <button class="button is-warning is-large" slot="trigger">
                <span style="text-transform: capitalize;">{{ action }}</span>
                <b-icon icon="angle-down"></b-icon>
            </button>

            <b-dropdown-item @click="executeAction" aria-role="listitem" value="details"       >Details</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="summary"       >Summary</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="meetings"      >Meetings</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="devices"       >Devices</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="messages"      >Messages</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="lastgivers"    >Last Givers</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="monthlypayout" >Monthly Payments</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="weeklypayout"  >Weekly Payments</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="dailypayout"   >Daily Payments</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="login"  v-if="!isloggedIn">Login</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="logout" v-if="isloggedIn">Logout</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="topgiver"      >Top Giver</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="topreceiver"   >Top Receiver</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="top"           >Top Visitor</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="hours"         >Hours</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="weekdays"      >Weekdays</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="skyboxes"      >Skyboxes</b-dropdown-item>
            <!--
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="jacqui"        >Jacqui</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="unique"        >Unique</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="ground"        >Ground</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="top"           >Top Visitor</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="hours"         >Hours</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="weekdays"      >Weekdays</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="lastgivers"    >Last Givers</b-dropdown-item>
            -->
          </b-dropdown>

          <b-dropdown v-model="region" aria-role="list" style="margin-left:0; margin-right:0.75rem;" v-if="!showOptions">
            <button class="button is-warning is-large" slot="trigger">
              <span style="text-transform: capitalize;">{{ region }}</span>
              <b-icon icon="angle-down"></b-icon>
            </button>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="">(All)</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="The Bay">The Bay</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="Bid">Bid</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="Iron Ring">Iron Ring</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="Salamander">Salamander</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="Coconut Island">Coconut Island</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="Sandy Grounds">Sandy Grounds</b-dropdown-item>
          <!--
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="Pitcairn Isle">Pitcairn Isle</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="Surreal Orange">Surreal Orange</b-dropdown-item>
            <b-dropdown-item @click="executeAction" aria-role="listitem" value="Denlock">Denlock</b-dropdown-item>
          -->
          </b-dropdown>

          <b-field label="Retro" label-position="inside" v-if="showOptions">
            <b-numberinput v-model="days" type="is-warning" controls-position="compact" style="width:8rem;">
            </b-numberinput>
          </b-field>

          <b-field label="Limit" label-position="inside" v-if="showOptions">
            <b-numberinput v-model="limit" type="is-warning" step="100" controls-position="compact" style="width:8rem;">
            </b-numberinput>
          </b-field>

          <b-field label="Min" label-position="inside" v-if="showOptions">
            <b-numberinput v-model="minutes" type="is-warning" controls-position="compact" style="width:8rem;">
            </b-numberinput>
          </b-field>

          <b-field type="is-warning" style="width:10rem;" v-if="showOptions">
             <b-input v-model="visitor" placeholder="" icon-right="user" size="is-large" focus @keydown.enter.native="executeAction"></b-input>
          </b-field>

          <b-field type="is-warning" style="width:10rem;" v-if="showOptions&&(action=='messages')">
            <b-input v-model="searchtext" placeholder="" icon-right="comment" size="is-large" focus @keydown.enter.native="executeAction"></b-input>
          </b-field>

          <b-field type="is-warning" style="width:10rem;" v-if="showOptions&&(action=='details'||action=='meetings'||action=='devices'||action=='messages')">
            <b-input v-model="searchlocation" placeholder="" icon-right="map-marker-alt" size="is-large" focus @keydown.enter.native="executeAction"></b-input>
          </b-field>

          <b-field type="is-warning" style="width:20rem;" v-if="showOptions&&(action=='messages')">
            <b-input v-model="searchtime" placeholder="" icon-right="clock" size="is-large" focus @keydown.enter.native="executeAction"></b-input>
          </b-field>

          <b-field label="Pictures" label-position="inside" style="margin-right:0;" v-if="showOptions&&(action!='messages')">
            <b-switch v-model="withPics" outlined :rounded="false" type="is-warning" style="padding-top:1.6rem;padding-left:0.75rem;"></b-switch>
          </b-field>

          <b-field label="AppLinks" label-position="inside" style="margin-left:0;" v-if="showOptions&&(action!='messages')">
            <b-switch v-model="appLinks" outlined :rounded="false" type="is-warning" style="padding-top:1.6rem;padding-left:0.75rem;"></b-switch>
          </b-field>

        </b-field>
      </div>
    </section>
    
    <section class="chart" v-if="isloggedIn">
      <div class="container" style="margin-bottom:1rem; width:100%; height:20%;">

        <line-chart ref="myLineChart" :chartData="lineChartData" :chartOptions="chartOptions" v-if="action=='summary'"/>
        <bar-chart ref="myBarChart" :chartData="barChartData" :chartOptions="chartOptions" v-if="action=='hours'||action=='weekdays'"/>

      </div>
    </section>
    
    <section class="filter" v-if="isloggedIn&&(visitor!='')">
      <div class="container" style="margin-top:1rem;margin-bottom:0;">
        <b-tabs id="filterTabs" type="is-boxed" v-model="action" @input="executeAction">
          <b-tab-item label="Profile"    value="profile"  ></b-tab-item>
          <b-tab-item label="Visits"     value="details"  ></b-tab-item>
          <b-tab-item label="Meetings"   value="meetings" ></b-tab-item>
          <b-tab-item label="Devices"    value="devices" ></b-tab-item>
          <b-tab-item label="Messages"   value="messages" ></b-tab-item>
          <b-tab-item label="Given"      value="tipsgiven" ></b-tab-item>
          <b-tab-item label="Received"   value="tipsreceived" ></b-tab-item>
          <b-tab-item label="Reset"      value="resetfilter" icon="filter"></b-tab-item>
        </b-tabs>
      </div>
    </section>

    <section class="results" v-if="isloggedIn&&(action!='hours'&&action!='weekdays')">
      <div class="container">
          <b-table :data="tabledata" :columns="columns" class="fullwidth"
          :mobile-cards="false" :bordered="false" :striped="true" :narrowed="true" :hoverable="false" :loading="isloading" 
          >
          </b-table>        
      </div>
    </section>

  </div>

</template>

<script>
import axios from 'axios'
import { isMobileOnly } from 'mobile-device-detect';
import { XORCipher } from './xorcipher.js';
import LineChart from "./components/LineChart.vue";
import BarChart from "./components/BarChart.vue";

export default {
  name: 'app',
  components: {
    LineChart,
    BarChart,
  },
  data() {
    return {
      appName: "visitor",
      API: "", // see created 
      API_created: false,
      baseURL: 'https://api.init.cc/', 
      headers: {
        'API-token': "",
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        },
      url: "",
      isloading: false,
      isloggedIn: false,
      firstaction: "details",
      action: "",
      region: "", // Bid
      visitor: "",
      chat: "",
      time: "",
      searchtext: "",
      searchlocation: "",
      searchtime: "",
      withPics: false,
      appLinks: false,
      showOptions: false,
      days: 0,
      limit: 100,
      minutes: 0,
      email: "",
      inputToken: "",
      hackUserid: "19640709",
      bodyReport: {
        'report': '',
        'region': '',
        'days': '',
        'limit': '',
        'minutes': '',
        'visitor': '',
        'text': '',
        'userid': '',
      },
      textMaxLength: 20000, 
      tabledata: [],
      tableRowSelected: {},
      columns: {},

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    suggestedMin: 0,
                }
            }]
        }
      },
      
      lineChartData: {
        labels: ['00','01'],
        datasets: [
          {
            label: 'Vistors',
            backgroundColor: '#f87979',
            borderColor: '#f87979',
            showLine: true,
            fill: false,
            data: [0, 4]
          }, {
            label: 'Visits',
            backgroundColor: '#7979f8',
            borderColor: '#7979f8',
            showLine: true,
            fill: false,
            data: [1, 0]
          }, {
            label: 'Hours around',
            backgroundColor: '#79f879',
            borderColor: '#79f879',
            showLine: true,
            fill: true,
            data: [2, 3 ]
          }
        ]
      },

      barChartData: {
        labels: ['00','01'],
        datasets: [
          {
            label: 'Around',
            backgroundColor: '#f87979',
            borderColor: '#f87979',
            showLine: true,
            fill: true,
            data: [0, 4]
          }
        ]
      },

    }; // return
  }, // data

  created: function() {
 //alert("C:"+this.email+"|"+this.bodyReport.userid+"|"+this.inputToken)
      this.url = new URL(window.location.href);
      this.headers['API-token'] = XORCipher.decode(this.hackUserid, this.getStorage("api-token","")); // this.bodyReport.userid
      this.days = this.getStorage("days","0"); 
      this.limit = this.getStorage("limit","100");  
      this.minutes = this.getStorage("minutes","0"); 
      this.region = this.getStorage("region",""); // Convert null to ""
      this.withPics = ( this.getStorage("withPics","0")=="1" )
      this.appLinks = ( this.getStorage("appLinks","0")=="1" )
      this.showOptions = ( this.getStorage("showOptions","0")=="1" )
      this.email = XORCipher.decode(this.hackUserid, this.getStorage("email","")); // this.bodyReport.userid
      this.isloggedIn = (this.email!="");
      this.visitor = this.url.searchParams.get("visitor") || ""; // Convert null to ""
      this.chat = this.url.searchParams.get("chat") || ""; // Convert null to ""
      this.time = this.url.searchParams.get("time") || ""; // Convert null to ""
      if (this.chat || this.time) { 
        this.action = "messages"; 
      } else if (this.visitor) { 
        this.action = "profile"; 
      } else {
        this.action = this.firstaction;
      }
 //alert("D:"+this.email+"|"+this.bodyReport.userid+"|"+this.inputToken)
      this.executeAction();
  }, // created
  methods: {
    createAPI () {
        this.API = axios.create({ baseURL: this.baseURL, headers: this.headers}); 
        this.API_created = true; // created: wird mehr als einmal durchlaufen zB wenn Modal benutzt wird
    },

    getStorage(nameStr, defaultStr) {
      let v = localStorage.getItem(this.appName + "-" + nameStr);  // returns null if nameStr does not exists
      return (v===null ? defaultStr : v);
    },

    setStorage(nameStr, valueStr) {
      localStorage.setItem(this.appName + "-" + nameStr, valueStr); 
    },

    defineColumns(report) {
      if (report=="details") {
        return ( 
                    [
                    { field: 'number',           label: '#',        sortable: true,  numeric: true,   visible: true },
                    { field: 'region_name',      label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'image_html',       label: 'Image',    sortable: false, numeric: false,  visible: this.withPics, renderHtml: true },  
                    { field: 'profile_link',     label: 'Visitor',  sortable: true,  numeric: false,  visible: !this.appLinks, renderHtml: true },  
                    { field: 'profile_app_link', label: 'Visitor',  sortable: true,  numeric: false,  visible: this.appLinks, renderHtml: true },  
                    { field: 'visitor_name',     label: 'Visitor',  sortable: true,  numeric: false,  visible: false },  
                    { field: 'in_out',           label: 'State',    sortable: true,  numeric: false,  visible: true },  
                    { field: 'entered_datetime', label: 'Entered',  sortable: true,  numeric: false,  visible: true },
                    { field: 'entered_date',     label: 'Day',      sortable: true,  numeric: false,  visible: false },
                    { field: 'since_days',       label: 'Since',    sortable: true,  numeric: true,   visible: false },
                    { field: 'left_range',       label: 'Left',     sortable: true,  numeric: false,  visible: true },
                    { field: 'secs_around',      label: 'Secs',     sortable: true,  numeric: true,   visible: false },
                    { field: 'time_around',      label: 'Stayed',   sortable: true,  numeric: false,  visible: true, renderHtml: true },
                    { field: 'posx',             label: 'X',        sortable: false, numeric: true,   visible: false },
                    { field: 'posy',             label: 'Y',        sortable: false, numeric: true,   visible: false },
                    { field: 'posz',             label: 'Z',        sortable: false, numeric: true,   visible: false },
                    { field: 'slurl',            label: 'Pos',      sortable: true,  numeric: false,  visible: true, renderHtml: true },  
                    ]
        );                      
      } else if (report=="unique") {
        return ( 
                    [
                    { field: 'number',           label: '#',        sortable: true,  numeric: true,   visible: true },
                    { field: 'region_name',      label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'image_html',       label: 'Image',    sortable: false, numeric: false,  visible: this.withPics, renderHtml: true },  
                    { field: 'profile_link',     label: 'Visitor',  sortable: true,  numeric: false,  visible: !this.appLinks, renderHtml: true },  
                    { field: 'profile_app_link', label: 'Visitor',  sortable: true,  numeric: false,  visible: this.appLinks, renderHtml: true },  
                    { field: 'visitor_name',     label: 'Visitor',  sortable: true,  numeric: false,  visible: false },  
                    { field: 'visits',           label: 'Visits',   sortable: true,  numeric: true,   visible: true },
                    { field: 'time_around',      label: 'Stayed',   sortable: true,  numeric: false,  visible: true },
                    { field: 'entered_date',     label: 'Day',      sortable: true,  numeric: false,  visible: false },
                    { field: 'entered_min',      label: 'From',     sortable: true,  numeric: false,  visible: true },
                    { field: 'left_max',         label: 'To',       sortable: true,  numeric: false,  visible: true },
                    { field: 'since_days',       label: 'Since',    sortable: true,  numeric: true,   visible: false },
                    { field: 'secs_around',      label: 'Secs',     sortable: true,  numeric: true,   visible: false },
                    ]
        );
      } else if ((report=="meetings")||(report=="devices")) {
        return ( 
                    [
                    { field: 'number',           label: '#',        sortable: true,  numeric: true,   visible: true },
                    { field: 'region_name',      label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'meeting_name',     label: ((report=='devices')?'Visitor':'Meeting'), sortable: true,  numeric: false,  visible: true, renderHtml: true },  
                    { field: 'in_out',           label: 'State',    sortable: true,  numeric: false,  visible: true },  
                    { field: 'starttime',        label: 'Start',    sortable: true,  numeric: false,  visible: true },
                    { field: 'endtime',          label: 'End',      sortable: true,  numeric: false,  visible: true },
                    { field: 'secs_around',      label: 'Secs',     sortable: true,  numeric: true,   visible: false },
                    { field: 'time_around',      label: 'Stayed',   sortable: true,  numeric: false,  visible: true, renderHtml: true },
                    { field: 'posx',             label: 'X',        sortable: false, numeric: true,   visible: false },
                    { field: 'posy',             label: 'Y',        sortable: false, numeric: true,   visible: false },
                    { field: 'posz',             label: 'Z',        sortable: false, numeric: true,   visible: false },
                    { field: 'slurl',            label: ((report=='devices')?'Device':'Pos'),  sortable: true,  numeric: false,  visible: true, renderHtml: true },  
                    { field: 'around',           label: 'Around',   sortable: true,  numeric: false,  visible: (report=='devices'), renderHtml: true },  
                    ]
        );
      } else if (report=="messages") {
        return ( 
                    [
                    { field: 'number',           label: '#',        sortable: true,  numeric: true,   visible: true },
                    { field: 'region_name',      label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'visitor',          label: 'Visitor',  sortable: true,  numeric: false,  visible: true, width: "10rem", renderHtml: true },  
                    { field: 'starttime',        label: 'Time',     sortable: true,  numeric: false,  visible: true, width: "12rem", renderHtml: true },
                    { field: 'message',          label: 'Message',  sortable: true,  numeric: false,  visible: true, renderHtml: true },  
                    { field: 'emoting',          label: 'Emoting',  sortable: true,  numeric: true,   visible: false },
                    { field: 'rating',           label: 'Rating',   sortable: true,  numeric: true,   visible: false },
                    { field: 'posx',             label: 'X',        sortable: false, numeric: true,   visible: false },
                    { field: 'posy',             label: 'Y',        sortable: false, numeric: true,   visible: false },
                    { field: 'posz',             label: 'Z',        sortable: false, numeric: true,   visible: false },
                    { field: 'slurl',            label: 'Pos',      sortable: true,  numeric: false,  visible: true, width: "10rem", renderHtml: true },  
                    ]
        );
      }
      else if (report=="summary") {
        return ( 
                    [
                    { field: 'number',           label: '#',        sortable: true,  numeric: true,   visible: false },
                    { field: 'region_name',      label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'entered_date',     label: 'Day',      sortable: true,  numeric: false,  visible: true },
                    { field: 'visitors',         label: 'Visitors', sortable: true,  numeric: true,   visible: true },
                    { field: 'visits',           label: 'Visits',   sortable: true,  numeric: true,   visible: true },
                    { field: 'secs_around',      label: 'Secs',     sortable: true,  numeric: true,   visible: false },
                    { field: 'time_around',      label: 'Around',   sortable: true,  numeric: false,   visible: true },
                    { field: 'time_around_avg',  label: 'Avg',      sortable: true,  numeric: false,   visible: true },
                    ]
        );
      }
      else if (report=="profile") {
        return ( 
                    [
                    { field: 'number',           label: '#',        sortable: true,  numeric: true,   visible: true },
                    { field: 'caption',          label: 'Caption',  sortable: true,  numeric: false,  visible: true },
                    { field: 'value',            label: 'Value',    sortable: false, numeric: false,  visible: true, renderHtml: true },  
                    ]
        );
      }
      else if (report=="top") {
        return ( 
                    [
                    { field: 'number',           label: '#',        sortable: true,  numeric: true,   visible: true },
                    { field: 'region_name',      label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'image_html',       label: 'Image',    sortable: false, numeric: false,  visible: this.withPics, renderHtml: true },  
                    { field: 'profile_link',     label: 'Visitor',  sortable: true,  numeric: false,  visible: true, renderHtml: true },  
                    { field: 'visitor_name',     label: 'Visitor',  sortable: true,  numeric: false,  visible: false },
                    { field: 'visits',           label: 'Visits',   sortable: true,  numeric: true,   visible: true },
                    { field: 'secs_around',      label: 'Secs',     sortable: true,  numeric: true,   visible: false },
                    { field: 'time_around',      label: 'Around',   sortable: true,  numeric: false,  visible: true },
                    { field: 'time_around_avg',  label: 'Avg',      sortable: true,  numeric: false,  visible: true },
                    { field: 'entered_min',      label: 'From',     sortable: true,  numeric: false,  visible: true },
                    { field: 'left_max',         label: 'To',       sortable: true,  numeric: false,  visible: true },
                    ]
        );
      }
      else if ((report=="skyboxes")||(report=="ground")) {
        return ( 
                    [
                    { field: 'number',           label: '#',        sortable: true,  numeric: true,   visible: true },
                    { field: 'region_name',      label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'location',         label: 'Location', sortable: true,  numeric: false,  visible: true },  
                    { field: 'visits',           label: 'Visits',   sortable: true,  numeric: true,   visible: true },
                    { field: 'secs_around',      label: 'Secs',     sortable: true,  numeric: true,   visible: false },
                    { field: 'time_around',      label: 'Around',   sortable: true,  numeric: true,   visible: true }, // numeric is a hack
                    { field: 'time_around_avg',  label: 'Avg',      sortable: true,  numeric: true,   visible: true }, // numeric is a hack
                    ]
        );                      
      }
      else if ((report=="topgiver")||(report=="topreceiver")) {
        return ( 
                    [
                    { field: 'number',           label: '#',        sortable: true,  numeric: true,   visible: true },
                    { field: 'region',           label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'name',             label: ((report=="topgiver")?"Giver":"Receiver"),  sortable: true,  numeric: false,  visible: false },
                    { field: 'key',              label: 'Key',   sortable: true,  numeric: false,   visible: false },
                    { field: 'profile_link',     label: ((report=="topgiver")?"Giver":"Receiver"),  sortable: true,  numeric: false,  visible: true, renderHtml: true },  
                    { field: 'tips',             label: 'Tips',     sortable: true,  numeric: true,   visible: true },
                    ]
        );
      }
      else if ((report=="tipsgiven")||(report=="tipsreceived")) {
        return ( 
                    [
                    { field: 'number',           label: '#',        sortable: true,  numeric: true,   visible: true },
                    { field: 'region',           label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'name',             label: ((report=="topgiver")?"Giver":"Receiver"),  sortable: true,  numeric: false,  visible: false },
                    { field: 'log_created',      label: 'Date',     sortable: true,  numeric: false,  visible: true, width: "12rem" },
                    { field: 'key',              label: 'Key',      sortable: true,  numeric: false,   visible: false },
                    { field: 'profile_link',     label: ((report=="topgiver")?"Giver":"Receiver"),  sortable: true,  numeric: false,  visible: true, renderHtml: true },  
                    { field: 'tip',              label: 'Tip',      sortable: true,  numeric: true,   visible: true },
                    ]
        );
      }
      else if (report=="lastgivers") {
        return ( 
                    [
                    { field: 'number',            label: '#',        sortable: true,  numeric: true,   visible: true, width: "4rem" },
                    { field: 'region',            label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'log_created',       label: 'Date',     sortable: true,  numeric: false,  visible: true, width: "12rem" },
                    { field: 'tip',               label: 'Tip',      sortable: true,  numeric: true,   visible: true, width: "4rem" },
                    { field: 'profile_link',      label: 'To',       sortable: true,  numeric: false,  visible: true, renderHtml: true },  
                    { field: 'profile_link_from', label: 'From',     sortable: true,  numeric: false,  visible: true, renderHtml: true },  
                    ]
        );
      }
      else if ((report=="monthlypayout")||(report=="weeklypayout")) {
        return ( 
                    [
                    { field: 'number',            label: '#',        sortable: true,  numeric: true,   visible: true, width: "2rem" },
                    { field: 'region',            label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'date',              label: ((report=="monthlypayout")?"Month":"Week"),   sortable: true,  numeric: false,  visible: true, width: "12rem" },
                    { field: 'tips',              label: 'Tips',     sortable: true,  numeric: true,   visible: true, width: "4rem" },
                    ]
        );
      }
      else if (report=="dailypayout") {
        return ( 
                    [
                    { field: 'number',            label: '#',        sortable: true,  numeric: true,   visible: true, width: "2rem" },
                    { field: 'region',            label: 'Region',   sortable: true,  numeric: false,  visible: false },
                    { field: 'date',              label: 'Day',      sortable: true,  numeric: false,  visible: true, width: "12rem" },
                    { field: 'tips',              label: 'Tips',     sortable: true,  numeric: true,   visible: true, width: "4rem" },
                    ]
        );
      }
      else {
        return ( [] );
      }
    },

    executeAction() {
//alert(this.action);

      if (this.action!="details" && this.action!="meetings" && this.action!="devices" && this.action!="messages" && this.action!="profile" && this.action!="tipsgiven" && this.action!="tipsreceived") {
        this.visitor = "";
        this.searchtext = "";
        this.searchlocation = "";
        this.searchtime = "";
      }
      this.saveSettings(this.days, this.limit, this.minutes, this.region, this.withPics, this.appLinks, this.showOptions);

      if (this.action=="resetfilter") {
        this.resetSettings();
        document.location.href = "/";
        //this.action = "details";
      }

      if (this.action=="login") {
        this.$nextTick( function() { this.login(); } )
      } else if (this.action=="logout") {
        this.$nextTick( function() { this.logout(); } )
      } else if (this.action=="jacqui") {
        this.saveSettings(0, 100, 0, "bid", false, false, false);
        this.visitor = "jacquismith";
        this.action = 'details';
        this.readDataFromApi(this.action); // nextTick -> result table is not shown anymore
      } else {
        this.readDataFromApi(this.action); // nextTick -> result table is not shown anymore
      }

    },

    readDataFromApi(action) {
      let self = this;
      //self.tableRowSelected = {}; 
      self.tabledata = {};
      self.columns = self.defineColumns(self.action);
      self.isloading = true;
      self.bodyReport.report = action; // not self.action
      self.bodyReport.region = self.region;
      self.bodyReport.days = self.days;
      self.bodyReport.limit = self.limit;
      self.bodyReport.minutes = self.minutes;
      self.bodyReport.visitor = self.visitor;
      self.bodyReport.text = self.searchtext;
      self.bodyReport.location = self.searchlocation;
      self.bodyReport.time = self.searchtime || self.time;
      self.bodyReport.chat = self.chat;
      if (!self.API_created) {
          self.createAPI();
      }
      self.API.post('visitor', self.bodyReport)
      .then(function(response) {
        self.isloading = false;
        self.tabledata = response.data;
        if (!response.data[0]) {
          self.$buefy.toast.open({
              message: 'No data found',
              position: ( isMobileOnly ? 'is-top-right' : 'is-bottom' ),
              type: 'is-warning',
              queue: false
          });
          //alert("Error - No data found: " + JSON.stringify(response));
        } else {
          if (self.bodyReport.report=="summary") {
            let s = [];
            let d0 = [];
            let d1 = [];
            let d2 = [];
            self.tabledata.forEach (function(p, index) {
              if (index>0) { // not actual day -> only whole days
                s[index] = p.entered_date;
                d0[index] = p.visitors;
                d1[index] = p.visits;
                d2[index] = Math.round(p.secs_around / 60 / 60 * 10) / 10; // One decimal
              } 
            });
            self.lineChartData.labels = s.reverse();
            self.lineChartData.datasets[0].data = d0.reverse();
            self.lineChartData.datasets[1].data = d1.reverse();
            self.lineChartData.datasets[2].data = d2.reverse();
            self.$refs.myLineChart.renderChart(self.lineChartData, self.chartOptions);
          } 
          else if (self.bodyReport.report=="hours") {
            self.barChartData.labels = ['0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
            let d0 = [];
            self.tabledata[0].forEach (function(p,index) { d0[index]=p.value; }); 
            self.barChartData.datasets[0].data = d0; // substructure: 0 = values, 1 = avg
            self.$refs.myBarChart.renderChart(self.barChartData, self.chartOptions);
          }
          else if (self.bodyReport.report=="weekdays") {
            self.barChartData.labels = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];
            let d0 = [];
            self.tabledata[0].forEach (function(p,index) { d0[index]=p.value; }); 
            self.barChartData.datasets[0].data = d0; // substructure: 0 = values, 1 = avg
            self.$refs.myBarChart.renderChart(self.barChartData, self.chartOptions);
          }
        }
      })
       // eslint-disable-next-line
      .catch( function(response) {  // TODO
        self.isloading = false;
        self.$buefy.toast.open({
            message: 'Could not read report data',
            position: ( isMobileOnly ? 'is-top-right' : 'is-bottom' ),
            type: 'is-danger',
            queue: false
        });
        //alert("Error - Could not read report data: " + JSON.stringify(response));
      });
    },

    saveCredentials(token, userid, email) { // , nickname
      this.API.defaults.headers['API-token'] = token;
      this.headers['API-token'] = token;
      this.bodyReport.userid = userid;
      this.email = email;
      this.setStorage("userid", userid);
      this.setStorage("api-token", XORCipher.encode(userid, token));
      this.setStorage("email", XORCipher.encode(userid, email));
    },

    saveSettings(days=0, limit=100, minutes=0, region="", withPics=false, appLinks=false, showOptions=false) { 
      this.setStorage("days", days);
      this.setStorage("limit", limit);
      this.setStorage("minutes", minutes);
      this.setStorage("region", region);
      this.setStorage("withPics", ( withPics ? "1" : "0" ));
      this.setStorage("appLinks", ( appLinks ? "1" : "0" ));
      this.setStorage("showOptions", ( showOptions ? "1" : "0" ));
    },

    resetSettings() { 
      this.saveSettings();
    },

    login() {
//alert("A:"+this.email+"|"+this.bodyReport.userid+"|"+this.inputToken);    
      this.saveCredentials(this.inputToken, this.hackUserid, this.email); // Hack  // this.bodyReport.userid
      this.inputToken = "";
      this.isloggedIn = true;
      this.API_created = false;
//alert("B:"+this.email+"|"+this.bodyReport.userid+"|"+this.inputToken);    
      this.$buefy.toast.open({
          message: 'You logged in',
          position: ( isMobileOnly ? 'is-top-right' : 'is-bottom' ),
          type: 'is-success',
          queue: false
      });
      this.action = this.firstaction;
      this.executeAction();
/*      
      let self = this;
      this.$buefy.dialog.prompt({
          message: 'Your email',
          inputAttrs: { type: 'email', maxlength: 50, required: true,  },
          onConfirm: (newEmail) => {
            this.$buefy.dialog.prompt({
                message: 'Your token',
                inputAttrs: { type: 'password', value:'', maxlength: 50, required: true,  },
                onConfirm: (newToken) => {  
                  self.saveCredentials(newToken, self.bodyReport.userid, newEmail); // Hack 
                  self.isloggedIn = true;
                  self.API_created = false;
                  //alert(newEmail+"|"+newToken);
                  self.$buefy.toast.open({
                      message: 'You logged in',
                      position: ( isMobileOnly ? 'is-top-right' : 'is-bottom' ),
                      type: 'is-success',
                      queue: false
                  });
                  self.action = self.firstaction;
                  self.executeAction();
                }
            });
          }
      });      
*/      
    },

    logout() {
      this.saveCredentials("", "", ""); 
      localStorage.clear();
      this.API_created = false;
      this.isloggedIn = false;
      this.tabledata = {};
      this.$buefy.toast.open({
          message: 'You logged out',
          position: ( isMobileOnly ? 'is-top-right' : 'is-bottom' ),
          type: 'is-success',
          queue: false
      });
    },

  } // methods
}
</script>

<style>
#app {
  margin-top: 0;
  text-align: left;
}
#app .loading-overlay .loading-background { background-color:rgba(0,0,0,0.5); margin: 2em; }

#filterTabs, 
#filterTabs .b-tabs .tabs.is-boxed a, 
#filterTabs .b-tabs .tabs.is-boxed li a:hover, 
#filterTabs .b-tabs .tabs.is-boxed li a:focus, 
#filterTabs .b-tabs .tabs.is-boxed li.is-active a {
    background-color: black !important;
}
</style>
