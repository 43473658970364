import Vue from 'vue'

import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import './assets/nuclear.min.css' // nuclear, lumen, yeti, sandstone, lux, slate, materia, united, spacelab, solar
Vue.use(Buefy, {
  defaultIconPack: 'fas',
})
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

