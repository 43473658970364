<script>
import { Bar } from 'vue-chartjs';
export default {
  name: "BarChart",
  extends: Bar,
  props: ['chartData', 'chartOptions'],
  // mounted () {
  //   this.renderChart(this.chartData, this.chartOptions);
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>


